import React, { useState, useContext } from "react"
import { Link, NavLink as RouterNavLink } from "react-router-dom"
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavLink,
  Collapse,
  NavbarToggler,
  Button,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { PopupModal } from "react-calendly"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import logo from "../../assets/images/logo.png"
import { UserContext } from "../../contexts/UserContext"
import { signOut } from "../../utils/auth.utils"
import { withRouter } from "../../utils/routes.utils"
import "./header.scss"

const Header = (props) => {
  const [collapsed, setCollapsed] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { data } = useContext(UserContext)
  const name = data.fullname ? data.fullname.split(" ")[0] : ""
  const isDemoUser = data?.email === "demo@billgist.com"
  const { privacy, authenticated, router } = props
  const toggleNavbar = () => setCollapsed(!collapsed)
  const handleSignout = async () => {
    await signOut()
    return router.navigate("/")
  }
  return (
    <div className="mb-3 header-container">
      <Navbar
        expand="lg"
        className={`text-capitalize font-avenir sticky-top header-navbar py-lg-0 px-lg-5 ${
          privacy ? "container-lg" : ""
        }`}
      >
        <NavbarBrand tag={Link} to="/dashboard" className="ms-lg-n1 w-25">
          <img alt="Billgist Logo" src={logo} />
        </NavbarBrand>
        <NavbarToggler data-testid="toggler" onClick={toggleNavbar} className="navbar-toggler text-secondary">
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
        <Collapse isOpen={collapsed} navbar className="justify-content-end">
          <Nav className="ms-auto" navbar>
            {authenticated ? (
              <>
                {data.email_verified && (
                  <>
                    {(data.subscription_plan === "business_monthly" ||
                      data.subscription_plan === "business_yearly") && (
                      <NavItem>
                        <NavLink className="nav-link pt-1 mt-1">
                          <Button
                            size="sm"
                            className="schedule-btn "
                            onClick={() => {
                              setModalIsOpen(true)
                              toggleNavbar()
                            }}
                            disabled={isDemoUser}
                          >
                            Schedule My Free Consultation 🗓
                          </Button>
                          <PopupModal
                            url={"https://calendly.com/billgist/"}
                            prefill={{
                              email: data.email,
                              name: data.fullname,
                            }}
                            text="Schedule"
                            className="w-50 btn btn-primary text-light m-1"
                            open={modalIsOpen}
                            onModalClose={() => setModalIsOpen(false)}
                            rootElement={document.getElementById("root")}
                          />
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        tag={RouterNavLink}
                        className="nav-link pt-1 mt-2"
                        to={"/dashboard"}
                        onClick={toggleNavbar}
                      >
                        Dashboard
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={RouterNavLink}
                        className="nav-link pt-1 mt-2"
                        to={"/aws-integration"}
                        onClick={toggleNavbar}
                      >
                        AWS Integrations
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={RouterNavLink}
                        className="nav-link pt-1 mt-2"
                        to={"/ec2-rightsizing-recommendation"}
                        onClick={toggleNavbar}
                      >
                        Recommendations
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={RouterNavLink}
                        className="nav-link pt-1 mt-2"
                        to={"/notification"}
                        onClick={toggleNavbar}
                      >
                        Notifications
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink tag={RouterNavLink} className="nav-link pt-1 mt-2" to={"/cost-anomalies"}>
                        Cost Anomalies
                      </NavLink>
                    </NavItem> */}
                  </>
                )}

                <UncontrolledDropdown nav className="d-flex justify-content-start pe-2">
                  <DropdownToggle nav className="d-flex align-items-center p-0 mt-1 me-0">
                    {name}
                    {data.image ? (
                      <img
                        src={data.image.src}
                        className="rounded-circle ms-1"
                        width="40"
                        height="40"
                        alt="user profile avatar"
                      />
                    ) : (
                      <FontAwesomeIcon icon={faUserCircle} size="2x" className="ps-1" />
                    )}
                  </DropdownToggle>
                  <DropdownMenu end>
                    <RouterNavLink to={"/settings"} className="text-decoration-none" onClick={toggleNavbar}>
                      <DropdownItem data-testid="settings">Account Settings</DropdownItem>
                    </RouterNavLink>
                    <RouterNavLink to={"/organization"} className="text-decoration-none" onClick={toggleNavbar}>
                      <DropdownItem data-testid="organization">Organization</DropdownItem>
                    </RouterNavLink>
                    {data.email_verified && (
                      <>
                        <RouterNavLink to={"/subscription"} className="text-decoration-none" onClick={toggleNavbar}>
                          <DropdownItem>Billing</DropdownItem>
                        </RouterNavLink>
                      </>
                    )}

                    <DropdownItem data-testid="logout" onClick={handleSignout}>
                      Sign out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            ) : (
              <>
                {privacy ? (
                  <NavItem className="nav-item">
                    <NavLink
                      tag={Link}
                      to="/"
                      className="header-navbar-link nav-link my-2 home-button-padding fw-bold"
                      onClick={toggleNavbar}
                    >
                      Home
                    </NavLink>
                  </NavItem>
                ) : (
                  <>
                    <NavItem>
                      <NavLink className="nav-link" href="#about" onClick={toggleNavbar}>
                        How It Works
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className="nav-link" href="#features" onClick={toggleNavbar}>
                        Features
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className="nav-link" href="#pricing" onClick={toggleNavbar}>
                        Pricing
                      </NavLink>
                    </NavItem>
                  </>
                )}
                <NavItem className="pe-lg-2">
                  <Button
                    tag={Link}
                    className="px-3 navbar-login-button my-2 button-margin fw-bold"
                    to="/sign-in"
                    data-testid="login"
                  >
                    Sign in
                  </Button>
                </NavItem>
                <NavItem>
                  <Button
                    tag={Link}
                    className="px-3 navbar-signup-button my-2 button-margin fw-bold"
                    to="/sign-up"
                    data-testid="signup"
                  >
                    Sign up
                  </Button>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>
      <div className="navbar-underline" />
    </div>
  )
}

export default withRouter(Header)
